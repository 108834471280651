import React, { useState, useRef } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ogImage from "../../../images/seo/navbars/navbars.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import NavbarHeroComponent from "../../../library/components/navbar/react/_preview/NavbarHeroComponent"

// Preview
import NavbarBasicPreview from "../../../library/components/navbar/react/_preview/basic"
import NavbarActionButtonPreview from "../../../library/components/navbar/react/_preview/action_button"
import NavbarIconsPreview from "../../../library/components/navbar/react/_preview/icons"
import NavbarAvatarPreview from "../../../library/components/navbar/react/_preview/avatar"
import NavbarTopBarPreview from "../../../library/components/navbar/react/_preview/topbar"
// All
const NavbarBasicJsx = require("!!raw-loader!../../../library/components/navbar/react/basic.jsx")
const NavbarBasicHTML = require("!!raw-loader!../../../library/components/navbar/html/basic.html")

const NavbarActionButtonJsx = require("!!raw-loader!../../../library/components/navbar/react/action_button.jsx")
const NavbarActionButtonHTML = require("!!raw-loader!../../../library/components/navbar/html/action_button.html")

const NavbarIconsJsx = require("!!raw-loader!../../../library/components/navbar/react/icons.jsx")
const NavbarIconsHTML = require("!!raw-loader!../../../library/components/navbar/html/icons.html")

const NavbarAvatarJsx = require("!!raw-loader!../../../library/components/navbar/react/avatar.jsx")
const NavbarAvatarHTML = require("!!raw-loader!../../../library/components/navbar/html/avatar.html")

const NavbarTopBarJsx = require("!!raw-loader!../../../library/components/navbar/react/topbar.jsx")
const NavbarTopBarHTML = require("!!raw-loader!../../../library/components/navbar/html/topbar.html")

const NavbarsPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "NavbarBasic",
      title: "Basic navigation",
      active_tab: 1,
    },
    {
      component_name: "NavBarActionButton",
      title: "With CTA button",
      active_tab: 1,
    },
    {
      component_name: "NavBarWithIcons",
      title: "With icons",
      active_tab: 1,
    },
    {
      component_name: "NavBarAvatar",
      title: "With user avatar",
      active_tab: 1,
    },
    {
      component_name: "NavbarTopBar",
      title: "With top bar",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Navigation Bars - WindUI Component Library"
        ogtitle="Tailwind CSS Navigation Bars - WindUI Component Library"
        description="Navigation bar components offer a persistent and convenient way to switch between primary destinations in an app. Built with Tailwind CSS by WindUI."
        ogdescription="Navigation bar components offer a persistent and convenient way to switch between primary destinations in an app. Built with Tailwind CSS by WindUI."
        url="components/navbars/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Navigation Bars, Navigation Bars, Navbars, Navigation Bar Components, Navbar Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Navigation Bars</h1>
          <p>
            Navigation bars offer a persistent and convenient way to switch
            between primary destinations in an app. A navigation bar
            implementation is considered one of the key points of Web design and
            usability.
          </p>

          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full flex-col justify-center overflow-hidden rounded-xl bg-wuiSlate-50 pb-[23rem] shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 lg:pb-36">
              <NavbarHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Basic Navigation */}
          <h3 id="NavbarBasic">
            Basic Navigation <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="NavbarBasic"
            HtmlComponent={NavbarBasicHTML.default}
            JsxComponent={NavbarBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 pb-[23rem] lg:pb-36"
            >
              <NavbarBasicPreview
                NavbarBasic={
                  activeTabs[0].active_tab === 1
                    ? NavbarBasicHTML.default
                    : NavbarBasicJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Navigation with CTA button*/}
          <h3 id="NavBarActionButton">
            Navigation with CTA button{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="NavBarActionButton"
            HtmlComponent={NavbarActionButtonHTML.default}
            JsxComponent={NavbarActionButtonJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 pb-[23rem] lg:pb-36"
            >
              <NavbarActionButtonPreview
                NavBarActionButton={
                  activeTabs[1].active_tab === 1
                    ? NavbarActionButtonHTML.default
                    : NavbarActionButtonJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Navigation with icons and cta button*/}
          <h3 id="NavBarWithIcons">
            Navigation with icons
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="NavBarWithIcons"
            HtmlComponent={NavbarIconsHTML.default}
            JsxComponent={NavbarIconsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 pb-[23rem] lg:pb-36"
            >
              <NavbarIconsPreview
                NavbarIcons={
                  activeTabs[2].active_tab === 1
                    ? NavbarIconsHTML.default
                    : NavbarIconsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Navigation with User Avatar*/}
          <h3 id="NavBarAvatar">
            Navigation with User Avatar{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="NavBarAvatar"
            HtmlComponent={NavbarAvatarHTML.default}
            JsxComponent={NavbarAvatarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full justify-center overflow-hidden rounded-xl bg-wuiSlate-50 pb-[23rem] lg:pb-36"
            >
              <NavbarAvatarPreview
                NavBarAvatar={
                  activeTabs[3].active_tab === 1
                    ? NavbarAvatarHTML.default
                    : NavbarAvatarJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Navigation with top bar*/}
          <h3 id="NavbarTopBar">
            Navigation with top bar{" "}
            <span className="info-tip">Requires JS</span>
          </h3>
          <PreviewBlock
            id="NavbarTopBar"
            HtmlComponent={NavbarTopBarHTML.default}
            JsxComponent={NavbarTopBarJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="flex w-full flex-col justify-center overflow-hidden rounded-xl bg-wuiSlate-50 pb-[24rem] lg:pb-36"
            >
              <NavbarTopBarPreview
                NavbarTopBar={
                  activeTabs[4].active_tab === 1
                    ? NavbarTopBarHTML.default
                    : NavbarTopBarJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Use a navigation bar to aid visitors in accessing the information on
            your app. A navigation bar appears at the top of the screen across a
            website or app, allowing visitors to access the most useful pages
            quickly.
          </p>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>A navbar consists of the following main components:</p>
          <ul>
            <li>
              <strong>Header:</strong> a <code>&lt;header&gt;</code> container
              for the navbar. It includes a <code>&lt;div&gt;</code> that acts
              as a container that prevents the content to spread to the full
              width of the page (to increase readability). Removing the{" "}
              <code>max-w-*</code> classes from that inner{" "}
              <code>&lt;div&gt;</code> will allow the navigation to expand to
              the full width. Add a "sticky top-0" class to the{" "}
              <code>&lt;header&gt;</code> to make it stick to the top of the
              screen as users scrolls down.
            </li>
            <li>
              <strong>Brand:</strong> the company logo (text, image or both).
            </li>
            <li>
              <strong>Navigation links:</strong> a list that contains links to
              your main pages. It uses a <code>&lt;nav&gt;</code> element that
              includes a list for the links. The height of the whole navbar is
              defines in this <code>&lt;nav&gt;</code> (in our examples 5.5rem
              (88px)). On small screens, the list of links when open expands to
              the full height of the screen.
            </li>
            <li>
              <strong>Actions: </strong> Extra actions that can be useful to
              reside on the navbar (CTA button, user avatar, cart icon etc).
            </li>
            <li>
              <strong>Mobile menu trigger button: </strong> visible only on
              small screens, it allows the menu to expand.
            </li>
            <li>
              <strong>Top bar (optional): </strong> a two-column layout that can
              host extra information (like social icons or a phone number). The
              text in the top bar is 0.875rem (14px) and its total height is
              2.25rem(36px).
            </li>
          </ul>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              The navigation list wrapper should have a{" "}
              <code>role=&quot;navigation&quot;</code> (not required if a native{" "}
              <code>&lt;nav&gt;</code> is used.)
            </li>
            <li>
              The trigger button has an <code>aria-expanded</code> role with a
              true/false value, that allows vocalizing menu states (open or
              closed).
            </li>
            <li>
              If a user is on a page that is within the navigation menu the{" "}
              <code>aria-current=&quot;page&quot;</code> attribute should be
              assigned to the item corresponding to the active page.
            </li>
            <li>
              A <code>role=&quot;menuitem&quot;</code> is used on all the menu
              links to indicate that an element is an option in a set of choices
              contained by the navbar.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default NavbarsPage
